<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="报装来源" class="lab">
              <el-select v-model="search.sourceType" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in sourceList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="报装状态" class="lab">
              <el-select v-model="search.status" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="用气类型" class="lab">
              <el-select v-model="search.type" clearable placeholder="请选择" @change="typyChange" class="SearchOptions">
                <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="用途" class="lab">
              <el-select v-model="search.useDesc" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in useList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="共享状态" class="lab">
              <el-select v-model="search.shareFlag" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in shareList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="" class="lab">
              <el-select v-model="search.timeType"  placeholder="请选择" class="SearchOptions2">
                <el-option
                v-for="item in dateTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item>
               <el-date-picker
                class="SearchDate"
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <span style="float:right;width:150px">
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
            </span>
          </el-form>
        </div>
        <div class="table">
          <div class="table-search1">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 15px 0;"
            border
             
            :stripe="true"
            @sort-change="sort_change"
            
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="sourceTypeName"
              label="报装来源"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop=""
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="typeName"
              label="用气类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="intime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
             
            >
            </el-table-column>
            <el-table-column
              prop="statusName"
              label="状态"
              align="center"
              show-overflow-tooltip
             
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.status==0" type="warning" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==1" type="primary" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==2" type="info" size="small" plain>{{row.statusName}}</el-tag>
              <el-tag v-if="row.status==3" type="success" size="small" plain>{{row.statusName}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column
              prop="shareName"
              label="共享状态"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                   <el-button
                    type="text"
                    size="small"
                    style="color:#F56C6C"
                    @click="share(row)" v-if="row.shareFlag==0"
                    >共享</el-button>
                     
                    <el-button
                    type="text" 
                    size="small" @click="desc(row,2)" 
                    >处理详情</el-button>
                  
                    
              </template>
            </el-table-column>
          </el-table>
          </div>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
      </div>
    </div>
    
    <applyEdit :id="id" :type="type" ref="applyEdit"></applyEdit>
    <applyDesc  :id="id" :type="type" ref="applyDesc"></applyDesc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzApply_pageShareSetting,bzApply_shareSetting,getDateTime} from '../../RequestPort/report/reportApply'
import applyEdit from './applyEdit.vue'
import applyDesc from './applyDesc.vue'
export default {
  name: 'reportApply',
  components: {applyEdit,applyDesc},
  mixins: [],
  data() {
    return {
       sourceList:[{ label: '公众号', value: '3' },{ label: '官网', value: '4' },{ label: '客服提交', value: '5' }],
      statusList:[{ label: '已申报', value: '0' },{ label: '受理中', value: '1' },{ label: '已取消', value: '2' },{ label: '已办结', value: '3' }],
      typeList:[{label:'工商户（企业客户）',value:'1'},{label:'个人户（居民客户）',value:'2'}],
      shareList:[{label:'未共享',value:'0'},{label:'已共享',value:'1'}],
      useList:[],
      dateTypeList:[{ label: '申请日期', value: '1' },{ label: '受理日期', value: '2' },{ label: '取消日期', value: '3' },{ label: '完结日期', value: '4' }],
      search: { current: 1, size: 100,timeType:'1'}, //搜索
      searchDatatime:[],
      total: 10,
      tableData: [], //列表对象
      id:'',
      type:'',
    }
  },
  mounted() {
    this.searchDatatime = getDateTime();
    this.getOption();
    this.getList();
  },
  methods: {
      getOption(){
        if(!this.search.type){
          selectDictListByParentCode({parentCode:'70001'}).then(res=>{
          if(res.code==200){
            var data1= res.data
             selectDictListByParentCode({parentCode:'70000'}).then(res=>{
              if(res.code==200){
                var data2 = res.data
                this.useList = data1.concat(data2)
              }
            })
          }
        })
        }else{
          let code = this.search.type==1?'70001':'70000';
        selectDictListByParentCode({parentCode:code}).then(res=>{
          if(res.code==200){
            this.useList = res.data
          }
        })
        }
        
      },
      typyChange(val){
        this.getOption();
      },
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        timeType:'1'
      }),
      this.useList =this.getOption();
      this.searchDatatime = getDateTime();
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      bzApply_pageShareSetting(this.search).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    sort_change(column){
      this.search.sortColumn=column.prop;
      if(column.order=='ascending'){
        this.search.sortKey =0
      }else{
        this.search.sortKey =1
      }
      this.getList(1)
    },

    handleSizeChange(val) {
      this.search.size = val
      this.getList(1)
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    },
    edit(row,type){
      this.id = row.id+"";
      this.type = type+""
      this.$refs.applyEdit.setdialogVisible(true)
    },
    desc(row,type){
      if(row.status==0){
         this.$message.success('暂未受理')
        return;
      }
      this.id = row.id+"";
      this.type = type+""
      this.$refs.applyDesc.setdialogVisible(true)
    },
    share(row){
      this.$confirm('确定同步政府系统，共享后不能撤回', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          let params = {
            id: row.id
          }
          bzApply_shareSetting(params).then(res => {
            if (res.code == 200) {
              this.$message.success('共享成功')
            }
            this.getList()
          })
        })
    }

  
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
  height:100px
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
   height: auto;
}
.all {
  background-color:#f3f4f8; height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
   height: calc(100vh - 70px);
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
